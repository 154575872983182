/**
 * Reformat the PressRelease object for use on the ArticleGrid component
 * @param  {Array} pressReleaseNodes
 * @return {Array}
 */
const pressReleaseDecorator = (pressReleaseNodes) => {
  return pressReleaseNodes.map((pressRelease) => {
    return {
      url: pressRelease.url,
      image: pressRelease.data.featured_image.thumbnails.grid.url ? pressRelease.data.featured_image.thumbnails.grid : null,
      category: pressRelease.data.categories[0].category.document.data.title.text,
      title: pressRelease.data.title.text,
      body: pressRelease.data.intro.text,
      inlineCta: 'Keep Reading'
    }
  })
}

export default pressReleaseDecorator
