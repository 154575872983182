import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Container from '../../shared/Container/Container'
import ArticleGridWithTitle from '../../shared/ArticleGrid/ArticleGridWithTitle'

// Utils
import pressReleaseDecorator from '../../../utils/pressReleaseDecorator'

// Local
import BlogArticlePressReleaseView from './BlogArticlePressReleaseView'

const PressReleaseView = ({ data }) => {
  return (
    <BlogArticlePressReleaseView
      data={data.prismicPressRelease.data}
      url={data.prismicPressRelease.url}
    >
      {data.relatedPressReleases.nodes.length > 0 && (
        <Container>
          <ArticleGridWithTitle
            className='mb-32'
            title={{
              raw: [{
                type: 'heading3',
                text: 'Related posts'
              }]
            }}
            articles={pressReleaseDecorator(data.relatedPressReleases.nodes)}
            singleRow
          />
        </Container>
      )}
    </BlogArticlePressReleaseView>
  )
}

PressReleaseView.propTypes = {
  data: PropTypes.object.isRequired
}

export default PressReleaseView
